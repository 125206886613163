/* 背景宇宙星光  */
#universe{
    display: block;
    position: fixed;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* 这个是调置顶的优先级的，-1在文章页下面，背景上面，个人推荐这种 */
    z-index: -1;
  }